import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Resources</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Apply</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Install CLI</AnchorLink>
  <AnchorLink mdxType="AnchorLink">References</AnchorLink>
    </AnchorLinks>
    <h2>{`Resources`}</h2>
    <h3>{`Base`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/argo/kustomization.yaml",
        "path": "/base/argo/kustomization.yaml"
      }}>{`---
apiVersion: kustomize.config.k8s.io/v1beta1
kind: Kustomization

namespace: argo

resources:
  - namespace.yaml
  - https://raw.githubusercontent.com/argoproj/argo-cd/stable/manifests/install.yaml

`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=/base/argo/namespace.yaml",
        "path": "/base/argo/namespace.yaml"
      }}>{`---
apiVersion: v1
kind: Namespace

metadata:
  name: argo

`}</code></pre>
    <h2>{`Applying`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`kubectl kustomize | kubectl apply -f - \\
&& kubectl wait -n argo --for=condition=available deployments/argocd-server --timeout=-1s
`}</code></pre>
    <h2>{`Install CLI`}</h2>
    <p><strong parentName="p">{`Darwin`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`brew install argocd
`}</code></pre>
    <p><strong parentName="p">{`Linux`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl -sSL -o /usr/local/bin/argocd https://github.com/argoproj/argo-cd/releases/latest/download/argocd-linux-amd64 \\
&& chmod +x /usr/local/bin/argocd
`}</code></pre>
    <h2>{`Authenticate`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`argocd login 127.0.0.1:8080 --username admin --password admin
`}</code></pre>
    <p>{`OR`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`argocd login 127.0.0.1:8080 \\
  --username=admin \\
  --password=\`kubectl -n argocd get secret argocd-initial-admin-secret -o jsonpath="{.data.password}" | base64 -d\` \\
  --insecure
`}</code></pre>
    <h2>{`References`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.padok.fr/en/blog/introduction-argocd-applicationset"
        }}>{`Quick introduction to ArgoCD ApplicationSet (Padok)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      